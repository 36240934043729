import React, { useEffect, useState } from "react";
import { Button, Link, Modal, Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import { color } from "../../res/color";
import TextInput from "../TextInput";
import authService from "../../../services/auth.service";
import { useDispatch } from "react-redux";
import { userAction } from "../../../actions/userAction";
import isEmail from "validator/lib/isEmail";
import { isValidUsername } from "../../../helper/functionHelper";

const LoginModal = (props) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLogin, setLogin] = useState(true);

  const dispatch = useDispatch();

  function resetData() {
    setUsername("")
    setEmail("")
    setPassword("")
    setConfirmPassword("")
  }

  function isButtonDisabled() {
    if(isLogin)
      return !username || !password
    else
      return !username || !password || !confirmPassword || !email
  }

  const validateRegistrationData = (value) => {
    let message = ""

    if(!isValidUsername(username)) {
      return (
        message = "Invalid username. Should only contain alphanumeric characters"
      )
    }
    if (username.length < 5 || username.length > 20) {
      return (
        message = "Username must consist of 5 to 20 characters"
      )
    }
    if (email && !isEmail(email)) {
      return (
        message = "Email not valid"
      )
    }
    if (password.length < 8 || password.length > 20) {
      return (
        message = "Password must consist of 8 to 20 characters"
      )
    }
    if (confirmPassword !== password) {
      return (
        message = "Password mismatch"
      )
    }
  };

  const handleRegister = (e) => {

    let validation = validateRegistrationData()

    if (!validation) {
      setLoading(true)
      authService.register(username, email, password, props.roleType ? props.roleType : 3)
      .then((res) => {
        if(res.isSuccess) {
          handleLogin()
        } else {
          props.onShowMessage(res.data)
          setLoading(false)
        }
      })
    } else {
      props.onShowMessage(validation)
    }
  };


  const handleLogin = () => {

    if(!isValidUsername(username)) {
      props.onShowMessage("Invalid username. Should only contain alphanumeric characters")
      return
    }

    setLoading(true)
    authService.loginUser(username, password)
      .then((res) => {
       
        if (res.isSuccess) {
          resetData()
          dispatch(userAction(res.data))
          props.onClose()
          props.onShowMessage('Login successful')
        } else {
          props.onShowMessage(res.data)
        }
        setLoading(false)
      })
  };

  return (
    <Modal
      sx={{ alignContent: 'center', justifySelf: 'center' }}
      open={props.isOpen}
      onClose={() => { 
        setLogin(true)
        props.onClose() 
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="card modal-container">
        <Typography style={{ color: color.white, textAlign: 'center' }}>{isLogin ? 'LOGIN' : 'REGISTRATION'}</Typography>

        <TextInput label="Username" onChangeText={(e) =>  setUsername(e.target.value)} value={username} required autoFocus type="text" />
        {!isLogin && <TextInput label="Email" onChangeText={(e) =>  setEmail(e.target.value)} value={email} required type="text" />}
        <TextInput label="Password" onChangeText={(e) =>  setPassword(e.target.value)} required value={password} type="password" />
        {!isLogin &&  <TextInput label="Confirm Password" onChangeText={(e) =>  setConfirmPassword(e.target.value)} value={confirmPassword} type="password" required />}
        <CustomButton disabled={isButtonDisabled()} loading={loading} text={isLogin ? 'LOGIN' : 'REGISTER'} onClick={() => {
          isLogin ? handleLogin() : handleRegister()
        }} />
        <Link 
        underline="none"
        component="button"
        disabled={loading}
        style={{fontSize: 12, color: color.pink, textAlign: 'left', marginTop: '15px'}}
        onClick={() => { 
          resetData()
          setLogin(!isLogin)
        }}
        >{isLogin ? 'Are you a new user?' : 'Already have an account?'}
        </Link>
      </div>
    </Modal>
  )
};

export default LoginModal;
