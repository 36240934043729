import { Button } from "@mui/material";
import React from "react";
import { color } from "../../res/color";

const NavigationItem = (props) => {

  function isSelected() {
    if(props.text.toLowerCase() === 'logout' || props.text.toLowerCase() === 'login/register') {
      return false
    } else if(props.locationName.toLowerCase().replace("home", "") === props.pathname.toLowerCase().replace("home", "")) {
      return true
    } else {
      return false
    }
  }
  return (
    <Button
      {...props}
      key={props.text}
      href={props.pathname}
      startIcon={props.icon}
      style={{
        color: color.white,
        marginLeft: '10px',
        marginRight: '10px',
        textUnderlinePosition: 'under',
        textDecoration: isSelected() ? 'underline' : 'none',
        fontWeight: isSelected() ? 'bold' : undefined
      }}
      >
      {props.text}
    </Button>
  )
};

export default NavigationItem;
