import axios from "axios";
import { getErrorMessage } from "../helper/functionHelper";



const findUsers = (value) => {
  return axios
    .get(process.env.REACT_APP_API_URL + "find-users", { params: { value } })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const updateUser = (id, username, email) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "update-user", {
      id, email, username
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const deleteUser = (id) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "delete-user", {
      id
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

// const getAdminBoard = () => {
//   return axios.get(process.env.REACT_APP_API_URL + "admin", { headers: authHeader() });
// };

export default {
  findUsers,
  updateUser,
  deleteUser
};
