import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomId
} from '@mui/x-data-grid-generator';
import modelService from '../../services/model.service';
import { color } from '../../components/res/color';
import { ClearOutlined, CopyAllOutlined, MoreHoriz, SearchOutlined } from '@mui/icons-material';
import CustomSnackBar from '../../components/ui/CustomSnackBar';
import ConfirmationModal from '../../components/ui/modal/ConfirmationModal';
import TextInput from '../../components/ui/TextInput';
import { isValidUsername } from '../../helper/functionHelper';
import { IconButton } from '@mui/material';

function EditToolbar(props) {
  const { setRows, setRowModesModel, isLoading } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => 
    [...oldRows, { id, username: '', coverUrl: '', liveUrl: '', isNew: true }]
     
    );
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'username' },
    }));
  };

  return (
    <GridToolbarContainer>
     <Button style={{ color: color.pink }} disabled={isLoading} startIcon={<AddIcon />} onClick={handleClick}>
        Add Model
      </Button>
    </GridToolbarContainer>
  );
}

export default function ManageModel() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isLoading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [isShowModal, setShowModal] = React.useState(false)
  const [actionType, setActionType] = React.useState(0)
  const [id, setId] = React.useState(0)
  const [searchText, setSearchText] = React.useState("")
  const [model, setModel] = React.useState(null)

  const searchUser = (user) => {
    setLoading(true)
    modelService.getModelByUsername(user)
      .then((res) => {
        if (res.isSuccess) {
          setRows([{...res.data}])
          setModel(res.data)
        } else {
          setMessage(res.data)
          clearRows()
        }
        setLoading(false)
      })
  };

  function clearRows() {
    setModel(null)
    setRows([])
  }


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setShowModal(true)
    setActionType(1)
    setId(id)
  };

  const updateModel = (newRow) => {
    setLoading(true)
    return modelService.updateModel(newRow.id, newRow.username, newRow.coverUrl, newRow.liveUrl)
      .then((res) => {
        setMessage(res.data)
        setLoading(false)
        if (res.isSuccess) {
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          return updatedRow;
        }
      })
  }

  const addModel = (newRow) => {
    setLoading(true)
    return modelService.addModel(newRow.id, newRow.username, newRow.coverUrl, newRow.liveUrl)
      .then((res) => {
        setMessage(res.data)
        setLoading(false)
        if (res.isSuccess) {
          const updatedRow = { ...newRow, isNew: false }; 
          searchUser(newRow.username)
          setSearchText(newRow.username)
          return updatedRow;
        }
      })
    
  };

  const handleDeleteClick = (id) => () => {
    setShowModal(true)
    setActionType(2)
    setId(id)
  };


  const handleCancelClick = (id) => () => {

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    if (!newRow.username || !newRow.coverUrl) {
      setMessage("Kindly check required fields")
      return
    }

    if (!isValidUsername(newRow.username)) {
      setMessage("Invalid username. Should only contain alphanumeric characters")
      return
    }

    if (newRow.isNew) {
      return addModel(newRow)
    } else {
      return updateModel(newRow)
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function isShowAdd() {
    if(isLoading) {
      return false
    } else {
      if(model) {
        return false
      }
      if(rows.length !== 0) {
        return false
      }
    }
    return true
  }

  const columns = [
    { field: 'username', headerName: 'Username*', width: 250, editable: true },
    {
      field: 'coverUrl',
      headerName: 'Cover Image Url*',
      width: 300,
      editable: true,
      renderCell: (params) => <img
        src={params.value}
        alt=""
        style={{
          // Make the image expand to cover the video's dimensions
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />, // renderCell will render the component
    },
    {
      field: 'liveUrl',
      headerName: 'Live Url',
      width: 300,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon style={{ color: color.pink }} />}
              label="Save"
              onClick={handleSaveClick(id)}
              disabled={isLoading}
            />,
            <GridActionsCellItem
              icon={<CancelIcon style={{ color: color.pink }} />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={isLoading}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon style={{ color: color.pink }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={isLoading}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon style={{ color: color.pink }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={isLoading}
          />,
          //   <GridActionsCellItem
          //   icon={<CopyAllOutlined style={{ color: color.pink }} />}
          //   label="Copy"
          //   onClick={() => {navigator.clipboard.writeText(id)}}
          //   color="inherit"
          //   disabled={isLoading}
          // />

        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: color.pink,
        },
        '& .text': {
          color: color.pink,
        },
        marginTop: '20px',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: color.white, alignItems: 'center', justifyItems: 'center', display: 'flex', paddingBottom: '20px' }}>
        <TextInput label="Search" value={searchText} size="small" onChangeText={(e) => {
          setSearchText(e.target.value)
        }} type="text" disabled={rows.length !== 0} />
        <IconButton
          color="inherit"
          edge="start"
          disabled={searchText.length === 0}
          onClick={() => {
            if(model) {
              clearRows()
              setSearchText('')
            } else {
              searchUser(searchText) 
            }
          }}
          sx={{ m: 2 }}
        >
          {model ? <ClearOutlined style={{ color: color.white }} /> :<SearchOutlined style={{ color: color.white }} />}
        </IconButton>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        hideFooter
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: isShowAdd() && EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: color.pink,
          fontSize: 13,
          // color: color.pink,
          backgroundColor: color.black,
          '& .MuiDataGrid-cell': {
            color: color.white,
          },
          '& .MuiDataGrid-cell:hover': {
            color: color.lightPink,
          },
          '.MuiInputBase-input': {
            border: color.lightPink,
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            border: 'none',
          }
        }}
      />
   
      <CustomSnackBar isOpen={message} message={message} onClose={() => { setMessage("") }} />
      <ConfirmationModal
        isOpen={isShowModal}
        text={`Are you sure?`}
        onClose={() => {
          setShowModal(false)
        }}
        onConfirm={() => {
          if (actionType === 2) {
            setLoading(true)
            modelService.deleteModel(id)
              .then((res) => {
                setMessage(res.data)
                if (res.isSuccess) {
                  setRows(rows.filter((row) => row.id !== id));
                }
                setLoading(false)
              })
          } else {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
          }
          setShowModal(false)
        }}
      />
    </Box>
  );
}