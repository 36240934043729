import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { color } from "./res/color";
import CustomButton from "./ui/CustomButton";
import { Box, Typography } from "@mui/material";
import ageAllowed, { ageAllowedAction } from "../actions/ageAllowedAction";


const Confirmation = () => {

  const dispatch = useDispatch();
  return (
    <div className="card modal-container" style={{maxWidth: '350px'}}>
      <Box sx={{ backgroundColor: color.black, borderColor: color.pink }}>
        <Typography id="keep-mounted-modal-title" variant="h6" component="h2" style={{ color: color.pink, textAlign: 'center' }}>
          THIS IS AN ADULT WEBSITE
        </Typography>
        <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }} style={{ color: color.white, textAlign: 'center' }}>
          This website contains age-restricted materials including nudity and explicit depictions of sexual activity. By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are accessing the website from and you consent to viewing sexually explicit content.
        </Typography>
        <CustomButton text="I am 18 or older - Enter" style={{ width: '100%' }}
          onClick={() => {
            dispatch(ageAllowedAction(true))
          }} />
        <CustomButton text="I am under 18 - Exit" style={{ width: '100%', backgroundColor: color.white, color: color.pink }} onClick={() => { window.location.replace('https://www.google.com') }} />
      </Box>
    </div>
  );
};

export default Confirmation;
