import React from "react";
import { color } from "../res/color";
import { Box, Button, CircularProgress } from "@mui/material";
const CustomButton = (props) => {
    return (
        <Button
            textAlign='center'
            textJustify='center'
            sx={{textJustify: 'center', justifyContent: 'center', alignContent: 'center'}}
            variant="contained"
            size="small" 
            disabled={props.disabled || props.loading}
            style={{backgroundColor: props.disabled ? color.disabled : props.type === "secondary" ? color.white : color.pink, color: props.type === "secondary" ? color.pink :  color.white, marginTop: '15px', ...props.style }}
            onClick={() => props.onClick()}>
            <div style={{ height: 25 }}>
                {props.loading ? <CircularProgress style={{ color: props.type === "secondary" ? color.pink : color.white }} size={23} /> : props.text}
            </div> 
        </Button>
    )
};

export default CustomButton;
