import { ImageList, ImageListItem, ImageListItemBar, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { color } from "../res/color";
import { RadioButtonChecked } from "@mui/icons-material";
import planService from "../../services/plan.service";
import PlanModal from "./modal/PlanModal";
import { useSelector } from "react-redux";

const CustomModelList = (props) => {
    const [plans, setPlans] = useState([]);
    const [openSubscription, setOpenSubscription] = useState(false);
    const { user: currentUser } = useSelector((state) => state.user);

    const getPlans = () => {
        planService.getPlans()
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.data
                    data.sort((a, b) => a.amount - b.amount)
                    setPlans(data)
                    setOpenSubscription(true)
                }
            })
    };

    return (
        <>
            <ImageList cols={props.isSmallScreen ? 2 : 4} sx={{
                mr: '20px', ml: '20px', '-ms-overflow-style': 'none',  // IE and Edge
                'scrollbar-width': 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                }
            }}>
                {props.data.map((item) => (
                    <ImageListItem key={item.coverUrl}
                        onClick={() => {
                            if(currentUser?.roleType === 3) {
                                getPlans()
                            }
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                            }}
                            srcSet={item.coverUrl}
                            src={item.coverUrl}
                            alt={item.id}
                            loading="lazy"
                        />
                        {item.liveUrl &&
                            <Stack sx={{ alignItems: 'center', borderRadius: 5, backgroundColor: color.white, m: .25, paddingRight: '5px', paddingLeft: '5px', paddingTop: '1px', paddingBottom: '1px', position: 'absolute', right: 0, zIndex: 1 }} direction="row" gap={.5} >
                                <RadioButtonChecked sx={{ color: color.green }} />
                                <Typography style={{ color: color.black, textAlign: 'center', fontSize: 12 }}>LIVE</Typography>
                            </Stack>
                        }
                        <ImageListItemBar
                            position="bottom"
                            subtitle={item.username}
                            sx={{ color: color.black, height: 30, fontSize: 12, zIndex: 1, fontWeight: 'bold' }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <PlanModal
                isOpen={openSubscription}
                data={plans}
                onClose={() => {
                    setOpenSubscription(false)
                }}
                onConfirm={(id) => {
                    setOpenSubscription(false)
               
                }} />
        </>
    )
};

export default CustomModelList;
