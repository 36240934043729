import axios from "axios";
import { getErrorMessage } from "../helper/functionHelper";

const register = (username, email, password, roleType) => {
  return axios.post(process.env.REACT_APP_API_URL + "register", {
    username,
    email,
    password,
    roleType
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    return getErrorMessage(error)
  });
};

const loginUser = (username, password) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "login", {
      username,
      password,
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const changePassword = (id, oldPassword, newPassword) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "change-password", {
      id,
      oldPassword,
      newPassword,
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const generateTempPassword = (id) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "generate-temp-password", {
      id
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

export default {
  register,
  loginUser,
  changePassword,
  logout,
  generateTempPassword
};
