import { AGE_ALLOWED } from "../actions/types";

const ageAllowed = (state = {}, action) => {
  switch(action.type){
      case AGE_ALLOWED:
          return {
              ageAllowed: action.payload
          }
    
      default:
          return state
  }
}

export default ageAllowed;

