import * as React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { color } from '../../components/res/color';
import CustomSnackBar from '../../components/ui/CustomSnackBar';
import ConfirmationModal from '../../components/ui/modal/ConfirmationModal';
import isEmail from 'validator/lib/isEmail';
import userService from '../../services/user.service';
import { useDispatch } from 'react-redux';
import { userAction } from '../../actions/userAction';
import { PasswordOutlined } from '@mui/icons-material';
import ChangePasswordModal from '../../components/ui/modal/ChangePasswordModal';
import { isValidUsername } from '../../helper/functionHelper';


export default function UpdateDetails(props) {
  const [rows, setRows] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isLoading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [isShowModal, setShowModal] = React.useState(false)
  const [isShowPasswordModal, setShowPasswordModal] = React.useState(false)
  const [actionType, setActionType] = React.useState(0)
  const dispatch = useDispatch()

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  React.useEffect(() => {
    setRows([{
      id: props.currentUser.id,
      username: props.currentUser.username,
      email: props.currentUser.email,
    }])
  }, [])

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setShowModal(true)
    setActionType(1)
  };

  const updateData = (newRow) => {
    setLoading(true)
    return userService.updateUser(newRow.id, newRow.username, newRow.email)
      .then((res) => {
        setMessage(res.data)
        setLoading(false)
        if (res.isSuccess) {
          setRowModesModel({ ...rowModesModel, [props.currentUser.id]: { mode: GridRowModes.View } });
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          return updatedRow;
        }
      })
  }


  const handleDeleteClick = (id) => () => {
    if (props.currentUser.roleType === 1) {
      setMessage("This is not available for admin user")
    } else {
      setShowModal(true)
      setActionType(2)
    }

  };


  const handleCancelClick = (id) => () => {

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    if (!newRow.username || !newRow.email) {
      setMessage("Kindly check required fields")
      return
    }

    if(!isValidUsername(newRow.username)) {
      setMessage("Invalid username. Should only contain alphanumeric characters")
      return
    }

    if (!isEmail(newRow.email)) {
      setMessage("Email not valid")
      return
    }
    return updateData(newRow)
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'username', headerName: 'Username*', width: 300, editable: true },
    {
      field: 'email',
      headerName: 'Email*',
      width: 300,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon style={{ color: color.pink }} />}
              label="Save"
              onClick={handleSaveClick(id)}
              disabled={isLoading}
            />,
            <GridActionsCellItem
              icon={<CancelIcon style={{ color: color.pink }} />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={isLoading}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon style={{ color: color.pink }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={isLoading}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon style={{ color: color.pink }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={isLoading}
          />,
          <GridActionsCellItem
            icon={<PasswordOutlined style={{ color: color.pink }} />}
            label="More"
            onClick={() => {
              setShowPasswordModal(true)
            }}
            color="inherit"
            disabled={isLoading}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: color.pink,
        },
        '& .text': {
          color: color.pink,
        },
        marginTop: '20px',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        hideFooter
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: color.pink,
          fontSize: 13,
          // color: color.pink,
          backgroundColor: color.black,
          '& .MuiDataGrid-cell': {
            color: color.white,
          },
          '& .MuiDataGrid-cell:hover': {
            color: color.lightPink,
          },
          '.MuiInputBase-input': {
            border: color.lightPink,
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            border: 'none',
          }
        }}
      />
      <CustomSnackBar isOpen={message} message={message} onClose={() => { setMessage("") }} />
      <ChangePasswordModal 
        currentUser={props.currentUser} 
        isOpen={isShowPasswordModal}
        onClose={() => {
          setShowPasswordModal(false)
        }}
        onShowMessage={(message) => {
          setMessage(message)
        }}
        />
      <ConfirmationModal
        isOpen={isShowModal}
        text={`Are you sure?`}
        onClose={() => {
          setShowModal(false)
        }}
        onConfirm={() => {
          if (actionType === 2) {
            setLoading(true)
            userService.deleteUser(props.currentUser.id)
              .then((res) => {
                setMessage(res.data)
                if (res.isSuccess) {
                  dispatch(userAction(null));
                }
                setLoading(false)
              })
          } else {
            setRowModesModel({ ...rowModesModel, [props.currentUser.id]: { mode: GridRowModes.View } });
          }
          setShowModal(false)
        }}
      />
    </Box>
  );
}