import { Snackbar } from "@mui/material";
import React from "react";
import { color } from "../res/color";
const CustomSnackBar = (props) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={props.isOpen}
            ContentProps={{
                sx: {
                    border: "1px solid #ffb6c1",
                    fontWeight: "bold",
                    textAlign: "center",
                    backgroundColor: color.black,
                    // centering our message
                    width: "100%",
                    "& .MuiSnackbarContent-message": {
                        width: "inherit",
                        textAlign: "center",
                    }
                }
            }}
            autoHideDuration={3000}
            onClose={() => {
                props.onClose()
            }}
            message={props.message}
        />
    )
};

export default CustomSnackBar;
