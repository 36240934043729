export function getErrorMessage(error) {
    return { data: (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString()};
}

export function getSuccessMessage(data) {
    return { isSuccess: true, data: data};
}

export function isValidUsername(val) {
    return /^[a-zA-Z0-9]{5,20}$/.test(val)
}