import axios from "axios";
import { getErrorMessage } from "../helper/functionHelper";

const getPlans = () => {
  return axios
    .get(process.env.REACT_APP_API_URL + "get-plans")
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};


const updatePlan = (id, name, description, duration, amount) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "update-plan", {
      id, name, description, duration, amount
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const addPlan = (id, name, description, duration, amount) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "add-plan", {
      id, name, description, duration, amount
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const deletePlan = (id) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "delete-plan", {
      id
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};


// const getAdminBoard = () => {
//   return axios.get(process.env.REACT_APP_API_URL + "admin", { headers: authHeader() });
// };

export default {
  getPlans,
  updatePlan,
  deletePlan,
  addPlan
};
