import React from "react";
import { Routes, Route } from "react-router-dom";

import Profile from "./screens/profile/Profile";

import { Box, createTheme, ThemeProvider, Toolbar } from "@mui/material";
import NavigationBar from "./components/ui/navigation/NavigationBar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { useSelector } from "react-redux";
import Confirmation from "./components/Confirmation";
import ManageData from "./screens/manage/ManageData";
import { color } from "./components/res/color";
import Home from "./screens/home/Home";
const App = () => {

  const theme = createTheme({
    palette: {
      primary: {
        main: color.lightPink,
      },
    },
    components: {
      MuiTabs: {
        indicator: {
          backgroundColor: color.lightPink,
          height: 3,
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            // Custom styles for the Tab component
            // textTransform: 'none', // Prevent uppercase transformation
            // fontSize: '14px', // Font size
            color: color.white, // Tab text color
            '&.Mui-selected': {
              fontWeight: 'bold', // Font weight
              color: color.lightPink, // Color for selected tab
            },
          },
        },
      },
    },
    multilineColor:{
      color: "#FFFFFF"
    }
  });
  
  theme.typography.responsive = {
    fontSize: '.8rem',
    '@media (min-width:600px)': {
      fontSize: '.9rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  };

  const { ageAllowed } = useSelector(state => state.ageAllowed);
  return (
   <ThemeProvider theme={theme}>
    {!ageAllowed ? <Confirmation /> : 
    <Box>
      <NavigationBar />
      <Box style={{ flex: 1 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Manage" element={<ManageData />} />
        </Routes>
      </Box>

      
    </Box>
    }
    </ThemeProvider>
  );
};

export default App;
