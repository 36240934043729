import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {

  const middleware = [thunk];

  let store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  let persistor = persistStore(store)
  return { store, persistor }
}

