import axios from "axios";
import { getErrorMessage } from "../helper/functionHelper";

const getModels = () => {
  return axios
    .get(process.env.REACT_APP_API_URL + "get-models")
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const getModelByUsername = (username) => {
  return axios
    .get(process.env.REACT_APP_API_URL + "get-one-model", { params: { username } })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};


const updateModel = (id, username, coverUrl, liveUrl) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "update-model", {
      id, username, coverUrl, liveUrl
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const addModel = (id, username, coverUrl, liveUrl) => {
  alert(id + " " + username + " " + coverUrl + " " + liveUrl + " " + process.env.REACT_APP_API_URL)
  return axios
    .post(process.env.REACT_APP_API_URL + "add-model", {
      id, username, coverUrl, liveUrl
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

const deleteModel = (id) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "delete-model", {
      id
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return getErrorMessage(error)
    });
};

// const getAdminBoard = () => {
//   return axios.get(process.env.REACT_APP_API_URL + "admin", { headers: authHeader() });
// };

export default {
  getModels,
  updateModel,
  deleteModel,
  addModel,
  getModelByUsername
};
