import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import { color } from "../../res/color";
const ConfirmationModal = (props) => {
    return (
        <Modal
            sx={{ alignContent: 'center', justifySelf: 'center' }}
            open={props.isOpen}
            onClose={() => { props.onClose() }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="card modal-container">
                <Typography id="modal-modal-title" sx={{ textAlign: 'center', color: color.white }}>
                    {props.text}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 1 }}>
                    <CustomButton text="YES" onClick={() => {
                        props.onConfirm()
                    }} />
                    <CustomButton text="NO" type="secondary" style={{ marginLeft: '10px' }} onClick={() => {
                        props.onClose()
                    }} />
                </Box>
            </div>
        </Modal>
    )
};

export default ConfirmationModal;
