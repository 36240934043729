import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import UpdateDetails from "./UpdateDetails";

const Profile = () => {

  const { user: currentUser } = useSelector((state) => state.user);

  if (!currentUser) {
    return <Navigate to="/Home" />;
  }

  return (

    <Box sx={{ margin: '20px' }}>
      <UpdateDetails currentUser={currentUser} />
    </Box>
  );
};

export default Profile;
