import React, { useEffect, useRef } from "react";
import { color } from "../../components/res/color";
import { Box, CircularProgress, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import ModelService from "../../services/model.service";
import CustomSnackBar from "../../components/ui/CustomSnackBar";
import CustomModelList from "../../components/ui/CustomModelList";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";



const Home = () => {
  const [data, setData] = React.useState([]);
  const [message, setMessage] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    getModels()
  }, [])

  const getModels = () => {
    setLoading(true)
    ModelService.getModels()
      .then((res) => {
        if (res.isSuccess) {
          setData(res.data)
        } else {
          setMessage(res.data)
        }
        setLoading(false)
      })
  };

  function getNoDataView() {
    if (isLoading) {
      return <Grid container sx={{alignContent: 'center', justifyContent: 'center', p: 10}}>
        <CircularProgress style={{ color: color.white }} size={50} />
        </Grid>
    } else {
      return <Typography style={{ color: color.white, padding: '20px', textAlign: 'center' }}>No data</Typography>
    }
  }

  return (
    <Box sx={{marginTop: '20px'}}>
      {data.length === 0 && getNoDataView()}
      <CustomModelList
        data={data}
        isSmallScreen={isSmallScreen}
      />
      <CustomSnackBar isOpen={message} message={message} onClose={() => { setMessage("") }} />
    </Box>
  );
};

export default Home;