import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';

import { color } from '../../components/res/color';
import CustomSnackBar from '../../components/ui/CustomSnackBar';
import ConfirmationModal from '../../components/ui/modal/ConfirmationModal';
import userService from '../../services/user.service';
import authService from '../../services/auth.service';
import { ArrowBackOutlined, MoreVert, SearchOutlined } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import TextInput from '../../components/ui/TextInput';

export default function ManageUser(props) {
  const [rows, setRows] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isLoading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [isShowModal, setShowModal] = React.useState(false)
  const [actionType, setActionType] = React.useState(0)
  const [id, setId] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [searchText, setSearchText] = React.useState("")
  const [item, setItem] = React.useState(null)
  const [isViewSubscription, setViewSubscription] = React.useState(false)

  const generatePassword = () => {
    setAnchorEl(null);
    authService.generateTempPassword(id)
      .then((res) => {
        setMessage(res.data)
        if (res.isSuccess) {
          setShowModal(false)
        }
      })
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  React.useEffect(() => {
  }, [])

  const searchUser = (user) => {
    setLoading(true)
    userService.findUsers(user)
      .then((res) => {
        if (res.isSuccess) {
          let data = []
          res.data.map((item) => {
            var createdDate = new Date(item.createdAt).toLocaleString();
            var deletedDate = new Date(item.deletedAt).toLocaleString();
            if (!item.deletedAt) {
              deletedDate = '-'
            }
            data.push({
              id: item.id,
              createdAt: createdDate,
              deletedAt: deletedDate,
              username: item.username,
              email: item.email
            })
          })
          setRows(data)

        } else {
          setMessage(res.data)
        }
        setLoading(false)
      })
  };


  const columns = [
    {
      field: 'createdAt', headerName: 'Created Date', width: 250
    },
    {
      field: 'deletedAt', headerName: 'Deleted Date', width: 250
    },
    {
      field: 'username', headerName: 'Username', width: 250
    },
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<MoreVert style={{ color: color.pink }} />}
            label="More"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setId(id)
              let item =  rows.find((row) => row.id === id)
              setItem(item)
            }}
            color="inherit"
            disabled={isLoading}
          />
        ]
      }
    },
  ];

  return (
    <React.Fragment>
      {!isViewSubscription ?
        <Box
          sx={{
            width: '100%',
            '& .actions': {
              color: color.pink,
            },
            '& .text': {
              color: color.pink,
            },
            marginTop: '20px',
          }}
        >

          <Box sx={{ borderBottom: 1, borderColor: color.white, alignItems: 'center', justifyItems: 'center', display: 'flex', paddingBottom: '20px' }}>
            <TextInput label="Search" value={searchText} size="small" onChangeText={(e) => {
              setSearchText(e.target.value)
            }} type="text" style={{}} />
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => {
                searchUser(searchText)
              }}
              sx={{ m: 2 }}
            >
              <SearchOutlined style={{ color: color.white }} />
            </IconButton>
          </Box>

          <DataGrid
            rows={rows}
            columns={columns}
            hideFooter
            processRowUpdate={processRowUpdate}
            onRowModesModelChange={handleRowModesModelChange}
            rowModesModel={rowModesModel}
            sx={{
              boxShadow: 2,
              border: 2,
              fontSize: 13,
              borderColor: color.pink,
              // color: color.pink,
              backgroundColor: color.black,
              '& .MuiDataGrid-cell': {
                color: color.white,
              },
              '& .Mui-disabled': {
                color: color.pink,
              },
              '& .MuiDataGrid-cell:hover': {
                color: color.lightPink,
              },
              '.MuiInputBase-input': {
                border: color.lightPink,
              },
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '&.MuiDataGrid-root': {
                border: 'none',
              }
            }}
          />
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                backgroundColor: color.white,
                color: color.pink,
                borderColor: color.pink,
                border: 1
              },
            }}
          >
             
            <MenuItem key={"generate"} sx={{ fontSize: 12 }}
              onClick={() => {
                if(item.deletedAt !== '-') {
                  setMessage('This user is already deleted')
                  setAnchorEl(null)
                  return
                }
                setShowModal(true)
                setActionType(2)
              }}>
              Generate Temporary Password
            </MenuItem>
          </Menu>
          <CustomSnackBar isOpen={message} message={message} onClose={() => { setMessage("") }} />
          <ConfirmationModal
            isOpen={isShowModal}
            text={`Are you sure?`}
            onClose={() => {
              setShowModal(false)
            }}
            onConfirm={() => {
              if (actionType === 1) {
                setLoading(true)
                setAnchorEl(null)
                userService.deleteUser(id)
                  .then((res) => {
                    setMessage(res.data)
                    if (res.isSuccess) {
                      searchUser(searchText)
                    }
                    setLoading(false)
                  })
              } else if (actionType === 2) {
                generatePassword()
              }
              setShowModal(false)
            }}
          />
        </Box>
        :
        <Box>
          <Button variant='outlined' style={{ color: color.white }} disabled={isLoading} startIcon={<ArrowBackOutlined />} onClick={() => { setViewSubscription(false) }}>
            Back
          </Button>
        </Box>
      }
    </React.Fragment>
  );
}