import React from "react";
import { Box, Grid, Modal, Paper, Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import { color } from "../../res/color";

const PlanModal = (props) => {

  return (
    <Modal
      sx={{ alignContent: 'center', justifySelf: 'center', mr: 10, ml: 10 }}
      open={props.isOpen}
      onClose={() => { props.onClose() }}
    >
        <Grid container sx={{justifyContent: 'center', backgroundColor: color.black}}>
          {props.data.map((item) => (
            <Grid item sx={{m: 2}}>
              <Paper style={{ backgroundColor: color.black, borderColor: color.pink, borderRadius: 25,  width: '200px' }}>
                <Box>
                  <Box>
                    <Typography style={{ color: color.pink, textAlign: 'center' }}>{item.name.toUpperCase()}</Typography>
                    <Typography style={{ color: color.white, marginTop: '5px', textAlign: 'center', fontSize: 10 }}>{item.description || '-'}</Typography>
                    <Typography style={{ color: color.white, marginTop: '15px', textAlign: 'center', fontSize: 12 }}>{item.duration} minutes</Typography>

                  </Box>
                  <Box>
                    <CustomButton disabled={props.isLoading} style={{ justifySelf: 'center', width: '100%' }} text={`${item.amount} USD`} onClick={() => {
                      props.onConfirm(item.id)
                    }} />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
    </Modal>
  )
};

export default PlanModal;
