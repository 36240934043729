export const color = {
    black: '#000000',
    lightBlack: '#444443',
    white: 'white',
    pink: '#E31C79',
    lightPink: "#ffb6c1",
    disabled: "#cccccc",
    red: '#FF0000',
    green: "#008000",
    lightGreen: "#90EE90"
}