import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { color } from "../../res/color";

const DrawerItem = (props) => {

  function isSelected() {
    if(props.text.toLowerCase() === 'logout' || props.text.toLowerCase() === 'login/register') {
      return false
    } else if(props.locationName.toLowerCase().replace("home", "") === props.pathname.toLowerCase().replace("home", "")) {
      return true
    } else {
      return false
    }
  }
  
  return (
    <ListItem key={props.text} disablePadding>
      <ListItemButton sx={{backgroundColor: isSelected() ? color.pink : color.black}}  href={props.pathname} onClick={() => props.onClick && props.onClick()}>
      <ListItemIcon sx={{color: color.white}} >
        {props.icon}
      </ListItemIcon>
        <ListItemText primary={props.text} style={{ color: color.white }} />
      </ListItemButton>
    </ListItem>
  )
};

export default DrawerItem;
