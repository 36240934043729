import React, { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import { color } from "../../res/color";
import TextInput from "../TextInput";
import authService from "../../../services/auth.service";
const ChangePasswordModal = (props) => {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const validateData = () => {
        let message = ""
    
        if (!oldPassword || !newPassword || !confirmPassword) {
          return (
            message = "All fields are required"
          )
        }
    
        if (newPassword.length < 8 || newPassword.length > 20) {
          return (
            message = "Password must consist of 8 to 20 characters"
          )
        }
        if (confirmPassword !== newPassword) {
          return (
            message = "Password mismatch"
          )
        }
        return message
      };
    
     const onChangeOldPassword = (e) => {
        const password = e.target.value;
        setOldPassword(password);
      };
    
    
      const onChangeNewPassword = (e) => {
        const password = e.target.value;
        setNewPassword(password);
      };
    
      const onChangeConfirmPassword = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);
      };
    
      const handleChangePassword = (e) => {
        let validation = validateData()
        props.onShowMessage(validation)
       
        if (!validation) {
          setLoading(true)
          authService.changePassword(props.currentUser.id, oldPassword, newPassword)
          .then((res) => {
            props.onShowMessage(res.data)
            if(res.isSuccess) {
              setOldPassword("")
              setNewPassword("")
              setConfirmPassword("")
              props.onClose()
            }
            setLoading(false)
          })
        }
      };
      
    return (
        <Modal
            sx={{ alignContent: 'center', justifySelf: 'center' }}
            open={props.isOpen}
            onClose={() => { props.onClose() }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="card modal-container">
                <Typography style={{ color: color.white, textAlign: 'center' }}>CHANGE PASSWORD</Typography>
                <TextInput label="Old Password" onChangeText={onChangeOldPassword} value={oldPassword} type="password" required />
                <TextInput label="New Password" onChangeText={onChangeNewPassword} value={newPassword} type="password" required />
                <TextInput label="Confirm Password" onChangeText={onChangeConfirmPassword} value={confirmPassword} type="password" required />


                <CustomButton disabled={!newPassword || !confirmPassword || !oldPassword} loading={loading} text={"SUBMIT"} onClick={() => {
                    handleChangePassword();
                }} />
                
            </div>
        </Modal>
    )
};

export default ChangePasswordModal;
