import React from "react";
import { color } from "../res/color";
import { TextField } from "@mui/material";

function TextInput(props) {
  return (
    <TextField
      {...props}
      sx={{
        "& .MuiOutlinedInput-root": {
          color: color.white,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: color.pink,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: color.pink,
              borderWidth: "3px",
            },
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: color.lightPink,
              borderWidth: "3px",
            },
          },
          '& input[type="password"]::-ms-reveal': {
            filter:
              'invert(100%)',
          },
          "&:hover:not(.Mui-focused)": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: props.disabled ? color.lightPink : color.pink,
            },
          },
        },
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: color.white,
        },
        "& .MuiInputLabel-outlined": {
          color: color.pink,
          "&.Mui-focused": {
            color: color.pink,
            fontWeight: "bold"
          },
          "&.Mui-disabled": {
            color: color.pink
          },
        },
      }}
      inputProps={{
        autocomplete: 'new-password'
      }}
      margin="normal"
      fullWidth
      size= "small"
      name={`${props.label}${Date()}`}
      label={props.label}
      autocomplete='new-password'
      autoSave="false"
      helperText={props.helperText}
      onChange={(e) => { props.onChangeText(e) }}
    />
  );
}
export default TextInput;