import React from "react";
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { color } from "../res/color";
import { makeStyles } from "@material-ui/core";

const CustomSelect = (props) => {
    const useStyles = makeStyles({
        formControl: {
            minWidth: 120,
        },
        root: {
            '&:before': {
                borderColor: 'white',
            },
            '&:after': {
                borderColor: 'white',
            },
            '&:not(.Mui-disabled):hover::before': {
                borderColor: 'white',
            },
        },
        label: {
            borderColor: color.white,
            color: color.white,
            "&.Mui-focused": {
                color: color.white,
            },
        },
        select: {
            "&:after": {
                borderColor: color.white,
            },
            "& .MuiSvgIcon-root": {
                color: color.white,
            },
        },
    })

    const classes = useStyles();

    return (
        <Box>
            {/* <FormControl sx={{ width: 300 }} className={classes.formControl}> */}
            {/* <InputLabel id="input-label" className={classes.label} style={{ color: color.white, borderColor: color.white }}>{props.label}</InputLabel> */}
            <Select
                size="small"
                value={props.type}
                style={{ color: color.white, minWidth: '300px', alignItems: 'center', ...props.style }}
                inputProps={{ color: color.white }}
                className={classes.select}
                input={<OutlinedInput sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: color.white,
                        color: color.white
                    },
                    "&:hover > .MuiOutlinedInput-notchedOutline": {
                        borderColor: color.white,
                        color: color.white
                    },
                    "&:focused > .MuiOutlinedInput-notchedOutline": {
                        borderColor: color.white,
                        color: color.white
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: color.white,
                        color: color.white
                    },
                    "& .MuiInputLabel-outlined": {
                        color: color.white,
                        "&.Mui-focused": {
                            color: color.white,
                            fontWeight: "bold"
                        },
                        "&.Mui-disabled": {
                            color: color.white
                        },
                    },
                }} />}
                onChange={(e) => { props.onChange(e.target.value) }}
            >
                {props.data.map((val) => (
                    <MenuItem
                        key={val}
                        value={val}
                    >
                        {val}
                    </MenuItem>
                ))}
            </Select>
            {/* </FormControl> */}

        </Box>
    );
};

export default CustomSelect;
