import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomId
} from '@mui/x-data-grid-generator';
import { color } from '../../components/res/color';
import CustomSnackBar from '../../components/ui/CustomSnackBar';
import ConfirmationModal from '../../components/ui/modal/ConfirmationModal';
import planService from '../../services/plan.service';

function EditToolbar(props) {
  const { setRows, setRowModesModel, isLoading } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, name: '', description: '', duration: '',  amount: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
        <Button style={{ color: color.pink }} disabled={isLoading} startIcon={<AddIcon />} onClick={handleClick}>
          Add Plan
        </Button>
    </GridToolbarContainer>
  );
}

export default function ManagePlan() {
  const [rows, setRows] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isLoading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [isShowModal, setShowModal] = React.useState(false)
  const [actionType, setActionType] = React.useState(0)
  const [id, setId] = React.useState(0)

  React.useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    planService.getPlans()
      .then((res) => {
        if (res.isSuccess) {
          setRows(res.data)
        } else {
          setMessage(res.data)
        }
        setLoading(false)
      })
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setShowModal(true)
    setActionType(1)
    setId(id)
  };

  const updateData = (newRow) => {
    setLoading(true)
    return planService.updatePlan(newRow.id, newRow.name, newRow.description, newRow.duration, newRow.amount)
    .then((res) => {
      setMessage(res.data)
      setLoading(false)
      if (res.isSuccess) {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }
    })
  }

  const addData = (newRow) => {
    setLoading(true)
    return planService.addPlan(newRow.id, newRow.name, newRow.description, newRow.duration, newRow.amount)
      .then((res) => {
        setMessage(res.data)
        setLoading(false)
        if (res.isSuccess) {
          const updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          return updatedRow;
        }
      })
  };

  const handleDeleteClick = (id) => () => {
    setShowModal(true)
    setActionType(2)
    setId(id)
  };


  const handleCancelClick = (id) => () => {

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    if(!newRow.name || !newRow.duration || !newRow.amount) {
      setMessage("Kindly check required fields")
      return
    }
    if (newRow.isNew) {
      return addData(newRow)
    } else {
      return updateData(newRow)
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'name', headerName: 'Name*', width: 250, editable: true },
    { field: 'description', headerName: 'Description', width: 250, editable: true },
    { field: 'duration', headerName: 'Duration (minutes)*', width: 150, editable: true },
    { field: 'amount', headerName: 'Amount (USD)*', width: 150, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon style={{ color: color.pink }} />}
              label="Save"
              disabled={isLoading}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon style={{ color: color.pink }} />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={isLoading}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon style={{ color: color.pink }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={isLoading}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon style={{ color: color.pink }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={isLoading}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: color.pink,
        },
        '& .text': {
          color: color.pink,
        },
        marginTop: '20px',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        hideFooter
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        sx={{
          boxShadow: 2,
          border: 2,
          fontSize: 13,
          borderColor: color.pink,
          // color: color.pink,
          backgroundColor: color.black,
          '& .MuiDataGrid-cell': {
            color: color.white,
          },
          '& .MuiDataGrid-cell:hover': {
            color: color.lightPink,
          },
          '.MuiInputBase-input': {
            border: color.lightPink,
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            border: 'none',
          }
        }}
      />
      <CustomSnackBar isOpen={message} message={message} onClose={() => { setMessage("") }} />
      <ConfirmationModal
        isOpen={isShowModal}
        text={`Are you sure?`}
        onClose={() => {
          setShowModal(false)
        }}
        onConfirm={() => {
          if (actionType === 2) {
            setLoading(true)
            planService.deletePlan(id)
              .then((res) => {
                setMessage(res.data)
                if (res.isSuccess) {
                  setRows(rows.filter((row) => row.id !== id));
                }
                setLoading(false)
              })
          } else {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
          }
          setShowModal(false)
        }}
      />
    </Box>
  );
}