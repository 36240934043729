import { USER_DATA } from "../actions/types";

const user = (state = {}, action) => {
  switch(action.type){
      case USER_DATA:
          return {
              user: action.payload
          }
    
      default:
          return state
  }
}
export default user;

