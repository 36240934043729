import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import ManageModel from "./ManageModel";
import CustomSelect from "../../components/ui/CustomSelect";
import ManagePlan from "./ManagePlan";
import ManageUser from "./ManageUser";
import { userAction } from '../../actions/userAction';

const ManageData = () => {
  const { user: currentUser } = useSelector((state) => state.user);
  const [type, setType] = useState(['Model']);
  const dispatch=useDispatch()


  useEffect(() => {
    if((!currentUser || currentUser.roleType === 3)) {
      dispatch(userAction(null))
    }
  },[currentUser])
  
  function getTypeData() {
    let types = []
    types.push("Model")
    types.push("User")
    types.push("Plan")
    return types
  }

  function getView() {
    switch (type[0]) {
      case "Model":
        return <ManageModel />
      case "Plan":
        return <ManagePlan />
      default:
        return <ManageUser currentUser={currentUser}/>
    }
  }

  return (
    <Box sx={{ margin: '20px' }}>
      {(!currentUser || currentUser.roleType === 3) ?
       
        <>
          <Navigate to="/Home" />
        </>
      :
      <>
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <CustomSelect
          label="Type"
          onChange={(val) => {
            let arr = []
            arr.push(val)
            setType(arr)
          }}
          type={type}
          data={getTypeData()}
        />
      </Box>

      <Box>
        {getView()}
      </Box>
      </>
}
    </Box>

  );
};

export default ManageData;
