import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, Toolbar, Typography } from "@mui/material";
import { color } from "../../res/color";
import NavigationItem from "../navigation/NavigationItem";
import DrawerItem from "../navigation/DrawerItem";
import { userAction } from "../../../actions/userAction";
import LoginModal from "../modal/LoginModal";
import CustomSnackBar from "../CustomSnackBar";
import logo from "../../../images/1.png"
import { HomeOutlined, LoginOutlined, LogoutOutlined, ManageSearchOutlined, PersonOutline } from "@mui/icons-material";

const NavigationBar = (props) => {
    const drawerWidth = 240;
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isShowLogin, setShowLogin] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const dispatch = useDispatch()

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const { user: currentUser } = useSelector((state) => state.user);

    let location = useLocation();


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" style={{ backgroundColor: color.pink }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon style={{ color: color.white }} />
                    </IconButton>
                    <Box component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                        <img alt={'logo'} src={logo} style={{ height: 50, resize: 'cover' }} />
                    </Box>


                    {/* <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: color.white }}
                    >
                        camgetme
                    </Typography> */}
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>

                        <NavigationItem icon={<HomeOutlined />} text="Home" pathname={"/Home"} locationName={location.pathname} />

                        {currentUser ? (
                            <>
                                {currentUser.roleType !== 3 && <NavigationItem icon={<ManageSearchOutlined />} text="Manage" pathname={"/Manage"} locationName={location.pathname} />}
                                <NavigationItem text="Profile" icon={<PersonOutline />} pathname={"/Profile"} locationName={location.pathname} />
                                <NavigationItem text="Logout" icon={<LogoutOutlined />} pathname={""} onClick={() => {
                                    dispatch(userAction(null))
                                }} locationName="" />
                            </>
                        ) : (
                            <>
                                <NavigationItem icon={<LoginOutlined />} text="Login/Register" pathname={""} onClick={() => { setShowLogin(true) }} locationName="" />                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: color.black },
                    }}
                >
                    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
                    <img alt={'logo'} src={logo} style={{ height: 100, resize: 'cover', margin: '20px' }} />
                        <Divider color={color.white} />
                        <List>
                            <DrawerItem icon={<HomeOutlined />} text="Home" pathname={"/Home"} locationName={location.pathname} />
                            {currentUser ? (
                                <>

                                    {currentUser.roleType !== 3 && <DrawerItem icon={<ManageSearchOutlined />} text="Manage" pathname={"/Manage"} locationName={location.pathname} />}
                                    <DrawerItem text="Profile" icon={<PersonOutline />} pathname={"/Profile"} locationName={location.pathname} />
                                    <DrawerItem text="Logout" icon={<LogoutOutlined />} pathname={""} onClick={() => {
                                        dispatch(userAction(null))
                                    }} locationName="" />

                                </>
                            ) : (
                                <>
                                    <DrawerItem icon={<LoginOutlined />} text="Login/Register" pathname={""} onClick={() => { setShowLogin(true) }} locationName="" />
                                </>
                            )}
                        </List>
                    </Box>
                </Drawer>
            </nav>
            <LoginModal
                currentUser={props.currentUser}
                isOpen={isShowLogin}
                onClose={() => {
                    setShowLogin(false)
                }}
                onShowMessage={(message) => {
                    setMessage(message)
                }}
            />
            <CustomSnackBar isOpen={message} message={message} onClose={() => { setMessage("") }} />
        </Box>
    )
};

export default NavigationBar;
